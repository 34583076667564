import React, {useEffect} from 'react';

import {useApi} from "../../providers/ApiProvider";
import Alert from "../ui/Alert";

function PersonalInfoCard(props) {
    const [userName, setUserName] = React.useState(props.name);
    const [userEmail, setUserEmail] = React.useState(props.email);
    const [userSettings, setUserSettings] = React.useState(props.settings);
    const [alertNotification, setAlertNotification] = React.useState({type: null, text: null});

    const [settingsUnits, setSettingsUnits] = React.useState(props.settings.units ? props.settings.units : 'metric');
    const [settingsHomeLat, setSettingsHomeLat] = React.useState(props.settings.home && props.settings.home[0] ? props.settings.home[0] : '');
    const [settingsHomeLng, setSettingsHomeLng] = React.useState(props.settings.home && props.settings.home[1] ? props.settings.home[1] : '');


    useEffect(() => {
        setUserName(props.name);
        setUserEmail(props.email);
        setUserSettings(props.settings);

        if (props.settings.home && props.settings.home[0]) {
            setSettingsHomeLat(props.settings.home[0]);
        } else {
            setSettingsHomeLat('');
        }

        if (props.settings.home && props.settings.home[1]) {
            setSettingsHomeLng(props.settings.home[1]);
        } else {
            setSettingsHomeLng('');
        }

        if (props.settings.units) {
            setSettingsUnits(props.settings.units);
        } else {
            setSettingsUnits('metric');
        }
    }, [props.name, props.email, props.settings]);

    useEffect(() => {
        setUserSettings({... userSettings, units: settingsUnits});
    }, [settingsUnits]);

    useEffect(() => {
        setUserSettings({... userSettings, home: [settingsHomeLat, settingsHomeLng]});
    }, [settingsHomeLat, settingsHomeLng]);


    const onSubmit = (e) => {
        e.preventDefault();

        const newPersonalInfo = {
            name: userName,
            email: userEmail,
            settings: userSettings
        };

        props.usersModel.changePersonalInfo(newPersonalInfo).then((response) => {
            if (response !== false) {
                setAlertNotification({
                    type: 'success',
                    text: 'Information changed successfully.'
                });

                props.resetPersonalInfo();
            } else {
                setAlertNotification({
                    type: 'danger',
                    text: 'Information change failed.'
                });
            }
        });
    }


    const onCancel = (e) => {
        e.preventDefault();

        props.resetPersonalInfo();
    }


    return (
        <div className="card w-100 position-relative overflow-hidden">
            <div className="card-body p-4">
                <h5 className="card-title fw-semibold">{'Personal Details'}</h5>
                <p className="card-subtitle mb-4">{'To change your personal detail, edit and save from here.'}</p>

                {alertNotification.text && (
                    <Alert
                        type={alertNotification.type}
                        text={alertNotification.text}
                    />
                )}

                <form>
                    <div className="d-flex flex-row justify-content-stretch gap-3">
                        <div className="mb-4 w-100">
                            <label htmlFor="exampleInputtext1" className="form-label fw-semibold">{'Your Name'}</label>
                            <input type="text" className="form-control" id="exampleInputtext1" defaultValue={userName} onChange={(e) => {
                                setUserName(e.target.value)
                            }}/>
                        </div>
                        <div className="w-100">
                            <label htmlFor="exampleInputtext" className="form-label fw-semibold">{'Email'}</label>
                            <input type="email" className="form-control" id="exampleInputtext" defaultValue={userEmail} onChange={(e) => {
                                setUserEmail(e.target.value)
                            }}/>
                        </div>
                    </div>

                    <div className="mb-4 w-100">
                        <label htmlFor="measurement-units" className="form-label fw-semibold">Measurement Units</label>
                        <select className="form-select" id="measurement-units" value={settingsUnits} onChange={(e) => setSettingsUnits(e.target.value)}>
                            <option value="metric">Metric</option>
                            <option value="imperial">Imperial</option>
                        </select>
                    </div>

                    <span className="fs-3 fw-semibold">Default Location</span>
                    <div className="d-flex flex-row justify-content-stretch gap-3">
                        <div className="mb-4 w-100">
                            <label htmlFor="lat" className="form-label fs-2 fw-semibold">Latitude</label>
                            <input type="text" className="form-control" id="lat" defaultValue={settingsHomeLat} onChange={(e) => {
                                setSettingsHomeLat(e.target.value)
                            }}/>
                        </div>
                        <div className="w-100">
                            <label htmlFor="lng" className="form-label fs-2 fw-semibold">Longitude</label>
                            <input type="text" className="form-control" id="lng" defaultValue={settingsHomeLng} onChange={(e) => {
                                setSettingsHomeLng(e.target.value)
                            }}/>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-end gap-3">
                        <button className="btn btn-primary" onClick={onSubmit}>{'Save'}</button>
                        <button className="btn btn-light-danger text-danger" onClick={onCancel}>{'Cancel'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PersonalInfoCard;
