import React, {useEffect, useState} from 'react';
import { Tooltip } from 'react-tooltip';
import MapHelper from "../../../../helpers/MapHelper";
import ShapeStyling from "../../shapes/ShapeStyling";
import ProjectShareModal from "../../../projects/ProjectShareModal";
import ImportFromFilesModal from "./ImportFromFilesModal";
import {useAuth} from "../../../../providers/AuthProvider";


export default function ImportExportControl({shapes, project, projectId, onShapesImported, onImportFromFiles}) {
    const {user} = useAuth();

    const [showImportFromFilesModal, setShowImportFromFilesModal] = React.useState(false);
    const [projectStatus, setProjectStatus] = useState(project.status ? project.status : '30_cancelled');


    useEffect(() => {
        if (project.status) {
            setProjectStatus(project.status);
        }
    }, [project.status]);

    const onExportClick = () => {
        let geojsonData = {};
        if (shapes) {
            const features = Object.values(shapes).map(element => element.geoJSON);
            geojsonData = {
                type: "FeatureCollection",
                features: features,
                properties: {
                    title: project.title,
                    description: project.description
                }
            };
        }

        const dataStr = JSON.stringify(geojsonData);
        const blob = new Blob([dataStr], {type: 'application/json'});
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'geoData-'+projectId+'.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }


    const onImportClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json,.geojson';
        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = JSON.parse(e.target.result);
                parseImportedFile(data);
            }
            reader.readAsText(file);
        }
        input.click();
    }


    const parseImportedFile = (data) => {
        let newShapeDrafts = {...shapes};
        if (data.type === 'FeatureCollection') {
            data.features.forEach((feature) => {
                const type = MapHelper.detectShapeType(feature);

                if (type) {
                    const id = ShapeStyling.generateRandomId();

                    newShapeDrafts[id] = {
                        id: id,
                        active: false,
                        type: type,
                        geoJSON: feature,
                        layerData: {}
                    };
                }
            });
        }

        const bounds = MapHelper.getGeoJSONBounds(data);

        onShapesImported(newShapeDrafts, bounds);
    }


    const onImportFromFilesClick = () => {
        setShowImportFromFilesModal(true);
    }


    const onImportFromFilesCancel = () => {
        setShowImportFromFilesModal(false);
    }


    const onImportFromFilesSubmit = (fileId) => {
        setShowImportFromFilesModal(false);
        onImportFromFiles(fileId);
    }


    return (
        <>
            <div className={'btn-group-vertical'+(projectStatus !== '00_in_progress' ? ' d-none' : '')+(user.type !== 'admin' ? ' d-none' : '')}>
                <button
                    onClick={onExportClick}
                    className={'btn btn-onmap '+(shapes && Object.keys(shapes).length ? '' : 'd-none')}

                    data-tooltip-content="Export shapes to GeoJSON file"
                    data-tooltip-place="right"
                    data-tooltip-id="import-export-tooltip"
                >
                    <i className={'ti ti-package-export fs-5'}></i>
                </button>

                <button
                    onClick={onImportClick}
                    className={'btn btn-onmap'+(projectStatus !== '00_in_progress' ? ' d-none' : '')}
                    data-tooltip-content="Import shapes from GeoJSON file"
                    data-tooltip-place="right"
                    data-tooltip-id="import-export-tooltip"
                >
                    <i className={'ti ti-package-import fs-5'}></i>
                </button>

                <button
                    onClick={onImportFromFilesClick}
                    className={'btn btn-onmap'+(projectStatus !== '00_in_progress' ? ' d-none' : '')}
                    data-tooltip-content="Import from My Files"
                    data-tooltip-place="right"
                    data-tooltip-id="import-export-tooltip"
                >
                    <i className={'ti ti-folder-up fs-5'}></i>
                </button>

                <Tooltip id="import-export-tooltip"/>
            </div>


            <ImportFromFilesModal
                visible={showImportFromFilesModal}
                onCancel={onImportFromFilesCancel}
                onClick={onImportFromFilesSubmit}
            />
        </>
    );
}