import * as L from "leaflet";
import * as turf from "@turf/turf";
import "leaflet.gridlayer.googlemutant";

export default class MapHelper {
    static defaultCenter = L.latLng(51.5062769,-0.1061091);
    static defaultZoomLevel = 15;
    static defaultZoomLevelDetailed = 17;
    static defaultMaxZoom = 20;
    static defaultMapType = 'google';


    static getDefaultCenter() {
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (user.settings && user.settings.home) {
                return L.latLng(user.settings.home[0], user.settings.home[1]);
            }
        }

        return MapHelper.defaultCenter;
    }


    static createOSMStandardLayer() {
        return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; OpenStreetMap contributors',
            detectRetina: true,
        });
    }


    static createGoogleStreetsLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createGoogleHybridLayer() {
        return L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            attribution: '&copy; Google Maps'
        });
    }


    static createESRIWorldImageryLayer() {
        return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 19,
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });
    }


    static createESRITopographicLayer() {
        return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: 19,
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });
    }


    static initMap(htmlContainer, position = null, zoom = null) {
        let map = L.map(htmlContainer, {
            fadeAnimation: true,
            zoomAnimation: true,
            zoomControl: false,
            doubleClickZoom: false,
            maxZoom: MapHelper.defaultMaxZoom,
        });

        //map.pm.setGlobalOptions(ShapeStyling.drawingOptions);


        let OSMStandardLayer = MapHelper.createOSMStandardLayer();
        let googleStreetsLayer = MapHelper.createGoogleStreetsLayer();
        let googleHybridLayer = MapHelper.createGoogleHybridLayer();
        let esriWorldImageryLayer = MapHelper.createESRIWorldImageryLayer();
        let esriTopographicLayer = MapHelper.createESRITopographicLayer();


        if (!position) {
            position = MapHelper.getDefaultCenter();
        }
        if (!zoom) {
            zoom = MapHelper.defaultZoomLevel;
        }
        map.setView(position, zoom);


        return {
            map: map,
            layers: {
                OSMStandard: OSMStandardLayer,
                googleStreets: googleStreetsLayer,
                google: googleHybridLayer,
                esriWorldImagery: esriWorldImageryLayer,
                esriTopographic: esriTopographicLayer,
            }
        };
    }


    static getUnionShape(shapes) {
        let geoJSONs = turf.polygon([]);

        Object.values(shapes).forEach((shape) => {
            if (shape.geoJSON) {
                if (shape.geoJSON.type === 'FeatureCollection') {
                    shape.geoJSON.features.forEach((feature) => {
                        geoJSONs = turf.union(feature.geometry, geoJSONs);
                    });
                } else if (shape.geoJSON.geometry && !['Point', 'LineString'].includes(shape.geoJSON.geometry.type)) {
                    //console.log(shape.geoJSON.geometry.type);
                    geoJSONs = turf.union(shape.geoJSON, geoJSONs);
                }
            }
        });


        return geoJSONs;
    }


    static coordsInShape(latLng, shape) {
        if (shape.geometry.type === 'LineString') {return false;}
        if (shape.geometry.type === 'Point') {return false;}
        let point = turf.point([latLng.lng, latLng.lat]);
        return turf.booleanPointInPolygon(point, shape);
    }


    static circleToPolygon(circle) {
        const center = circle.getLatLng();
        const radius = circle.getRadius();

        const options = {steps: 64, units: 'meters'}; // Increase steps for a smoother circle
        return turf.circle([center.lng, center.lat], radius, options);
    }


    static detectShapeType(feature) {
        if (feature.geometry.type === 'Polygon') {
            return 'Polygon';
        } else if (feature.geometry.type === 'LineString') {
            return 'polyline';
        } else if (feature.geometry.type === 'Point') {
            return 'marker';
        }
    }


    static getGeoJSONBounds(geoJSON) {
        const shape = L.geoJSON(geoJSON);
        return shape.getBounds();
    }


    static calculateBoundsDiameter(bounds) {
        return bounds.getNorthEast().distanceTo(bounds.getSouthWest());
    }
}