import React, {useEffect, useState} from "react";

import ShapeColorPicker from "./ShapeColorPicker";
import chroma from "chroma-js";
import {Tooltip} from "react-tooltip";


export default function ShapeToolbar(props) {
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [color, setColor] = useState(props.data.geoJSON && props.data.geoJSON.properties && props.data.geoJSON.properties.color ? props.data.geoJSON.properties.color : null);

    let baseUnion = true;
    if (props.data && props.data.geoJSON && props.data.geoJSON.properties && props.data.geoJSON.properties.union === false) {
        baseUnion = false;
    }
    const [union, setUnion] = useState(baseUnion);

    let baseVisible = true;
    if (props.data && props.data.geoJSON && props.data.geoJSON.properties && props.data.geoJSON.properties.visible === false) {
        baseVisible = false;
    }
    const [visible, setVisible] = useState(baseVisible);

    let baseClusterified = true;
    if (props.data.geoJSON && props.data.geoJSON.properties && (props.data.geoJSON.properties.clusterified === false)) {
        baseClusterified = false;
    }
    const [clusterified, setClusterified] = useState(baseClusterified);



    useEffect(() => {
        setColor(props.data.geoJSON && props.data.geoJSON.properties && props.data.geoJSON.properties.color ? props.data.geoJSON.properties.color : null);

        let baseUnion = true;
        if (props.data.geoJSON && props.data.geoJSON.properties && props.data.geoJSON.properties.union === false) {
            baseUnion = false;
        }
        setUnion(baseUnion);

        let baseClusterified = true;
        if (props.data.geoJSON && props.data.geoJSON.properties && (props.data.geoJSON.properties.clusterified === false)) {
            baseClusterified = false;
        }
        setClusterified(baseClusterified);
    }, [props.data.geoJSON]);


    const onToggleUnion = (e) => {
        e.preventDefault();

        setUnion(!union);
        props.onToggleUnion();
    }


    const onEditNotes = (e) => {
        e.preventDefault();

        props.onEditNotes(true);
    }


    const onRemove = (e) => {
        e.preventDefault();

        props.onRemove();
    }


    const onMinimizePanel = (e) => {
        e.preventDefault();

        props.onMinimizePanel(e);
    }


    const onInitRefreshClick = (e) => {
        e.preventDefault();

        props.onInitRefresh();
    }


    const onSaveToFilesClick = (e) => {
        e.preventDefault();

        props.onSaveToFiles();
    }


    const onPinsVisibleClick = (e) => {
        e.preventDefault();

        props.onPinsVisibleChange(!props.pinsVisible);
    }


    const onLayerToTopClick = (e) => {
        e.preventDefault();

        props.onLayerToTop();
    }


    const onLayerToBottomClick = (e) => {
        e.preventDefault();

        props.onLayerToBottom();
    }


    const onToggleVisible = (e) => {
        e.preventDefault();

        setVisible(!visible);
        props.onToggleVisible(!visible);
    }


    const onToggleClusterified = (e) => {
        e.preventDefault();

        if (props.onToggleClusterified) {
            setClusterified(!clusterified);
            props.onToggleClusterified();
        }
    }


    const onColorClick = (e) => {
        e.preventDefault();

        setColorPickerVisible(!colorPickerVisible);
    }


    const onColorChange = (color, event) => {
        setColor(color.hex);
    }


    const onColorChangeComplete = (color, event) => {
        props.onColorChange(color.hex);
    }


    let colorPickerStyle = {height: '7px', width: '7px', borderRadius: '7px'};
    if (color) {
        colorPickerStyle = {...colorPickerStyle, backgroundColor: color, display: 'block'};
    }


    return (
        <>
            <div className="btn-group flex-column justify-content-between shape-toolbar">
                <button onClick={onMinimizePanel} className={'btn border-0 p-2 mx-1 d-flex align-items-center'}>
                    <i className={'ti ti-arrows-diagonal-minimize-2 fs-4'}></i>
                    <span className={'fs-2 ms-2'}>Minimize Panel</span>
                </button>

                <hr className="my-2"/>

                <button onClick={onPinsVisibleClick} className={'btn border-0 p-2 mx-1 d-flex align-items-center'}>
                    <i className={'ti fs-4 ' + (props.pinsVisible ? 'ti-map-pin-off' : 'ti-map-pin')}></i>
                    <span className={'fs-2 ms-2'}>{props.pinsVisible ? 'Hide' : 'Show'} Objects</span>
                </button>

                <button
                    onClick={onToggleClusterified}
                    className={'btn border-0 p-2 mx-1 d-flex align-items-center'}

                    data-tooltip-content={!props.onToggleClusterified ? 'Available for Shapes with less than 10000 locations' : ''}
                    data-tooltip-place="left"
                    data-tooltip-id="shape-info-tooltip"
                >
                    <i className={'ti ti-grain fs-4'}></i>
                    <span className={'fs-2 ms-2'}>{clusterified ? 'Disable clustering' : 'Enable clustering'}</span>
                </button>

                <button onClick={onToggleUnion} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti fs-4 ' + (union ? 'ti-minus' : 'ti-plus')}></i>
                    <span className={'fs-2 ms-2'}>{union ? 'Exclude' : 'Include'} Properties</span>
                </button>

                <button onClick={onEditNotes} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-edit fs-4'}></i>
                    <span className={'fs-2 ms-2'}>Edit Notes</span>
                </button>

                <hr className="my-2"/>

                <button onClick={onToggleVisible} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-' + (visible ? 'eye-off' : 'eye') + ' fs-4'}></i>
                    <span className={'fs-2 ms-2'}>{visible ? 'Hide the Shape' : 'Show the Shape'}</span>
                </button>

                <button onClick={onColorClick} className={'btn border-0 p-2 mx-1 fs-3 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-palette fs-4'}></i>
                    <span className={'fs-2 ms-2'}>Recolor Shape</span>

                    <span className="ms-3" style={colorPickerStyle}></span>

                    <ShapeColorPicker
                        visible={colorPickerVisible}
                        color={color ? color : '#388E3C'}
                        onChange={onColorChange}
                        onChangeComplete={onColorChangeComplete}
                    />
                </button>

                <button onClick={onLayerToTopClick} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-sort-ascending-2 fs-4'}></i>
                    <span className={'fs-2 ms-2'}>Move to Top</span>
                </button>

                <button onClick={onLayerToBottomClick} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-sort-descending-2 fs-4'}></i>
                    <span className={'fs-2 ms-2'}>Move to Bottom</span>
                </button>

                <button onClick={onSaveToFilesClick} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti ti-device-floppy fs-4 '}></i>
                    <span className={'fs-2 ms-2'}>Save Shape to Files</span>
                </button>

                <hr className="my-2"/>

                <button onClick={onInitRefreshClick} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti ti-refresh-dot fs-4 '}></i>
                    <span className={'fs-2 ms-2'}>Refresh Properties</span>
                </button>

                <button onClick={onRemove} className={'btn border-0 p-2 mx-1 d-flex align-items-center' + (!props.isEditable ? ' d-none' : '')}>
                    <i className={'ti ti-trash-x fs-4 text-danger'}></i>
                    <span className={'fs-2 ms-2 text-danger'}>Remove the Shape</span>
                </button>
            </div>

            <Tooltip id="shape-info-tooltip"/>
        </>
    );
}