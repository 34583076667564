import BaseCrudModel from "./BaseCrudModel";

/**
 * Projects model
 *
 * @param {ApiHelper} api
 */
export default class ProjectsModel extends BaseCrudModel {
    baseUrl = '/projects';

    constructor(api) {
        super(api);
    }


    async validate(projectId, invitedHash, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/ml/' + invitedHash;

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async list(filter = null, sorting =  null, status = null, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (status) {
            query.status = status;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async listAdmin(filter = {}, errorHandler) {
        let url = this.baseUrl+'/admin-list';
        for (let key in filter) {
            if (key === 'sorting') {
                url += (url.indexOf('?') === -1 ? '?' : '&') + 'sort=' + filter[key].field + '&direction=' + filter[key].direction;
                continue;
            }
            if (filter[key]) {
                url += (url.indexOf('?') === -1 ? '?' : '&') + key + '=' + filter[key];
            }
        }

        const response = await this.api.getRequest(url);
        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        let url = this.baseUrl;
        if (typeof pk === 'object') {
            url += '/' + pk.pk;
            if (pk.ua) {
                url += '?ua=' + pk.ua;
            }
        } else {
            url += '/' + pk;
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async refreshUniversalAccessKey(pk, data, errorHandler) {
        let url = this.baseUrl + '/' + pk + '/refresh-ua';

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async getUniversalAccessKey(pk, errorHandler) {
        let url = this.baseUrl + '/' + pk + '/get-ua';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async requestReport(projectId, data, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/request-report';

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async copy(projectId, errorHandler) {
        const url = this.baseUrl + '/' + projectId + '/copy';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }
}