import React, {useEffect} from 'react';
import CryptoJS from 'crypto-js';
import SimpleModal from "../ui/SimpleModal";
import {useNotifications} from "../../providers/NotificationsProvider";
import ProjectShareAutocomplete from "./ProjectShareAutocomplete";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import {useApi} from "../../providers/ApiProvider";
import ProjectsModel from "../../models/ProjectsModel";

export default function ProjectShareModal({visible = false, projectId = '', onClick, onCancel, shares, onRemoveAccess}) {
    const notifications = useNotifications();
    const apiContext = useApi();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [shareLoading, setShareLoading] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [selectedLevel, setSelectedLevel] = React.useState('edit');
    const [universalAccessKey, setUniversalAccessKey] = React.useState('');


    useEffect(() => {
        if (!visible) {return;}

        projectsModel.getUniversalAccessKey(projectId).then((response) => {
            if (response !== false) {
                setUniversalAccessKey(response.key);
            } else {
                setUniversalAccessKey('');
            }
        });
    }, [visible]);


    const refreshUniversalAccessKey = () => {
        projectsModel.refreshUniversalAccessKey(projectId, {key: universalAccessKey}).then((response) => {
            if (response !== false) {
                setUniversalAccessKey(response.key);
            } else {
                setUniversalAccessKey('');
            }
        });
    }


    const onSelectUser = (userId) => {
        setSelectedUser(userId);
    }


    const onLevelChange = (e) => {
        setSelectedLevel(e.target.value);
    }


    const onShareClick = (e) => {
        e.preventDefault();

        if (selectedUser === '') {
            notifications.notify('Please select user to share with', 'error');
            return;
        }

        onClick(selectedUser, selectedLevel);
    }


    const onRemoveAccessClick = (shareId) => {
        setShareLoading(shareId);
        onRemoveAccess(shareId);
    }


    const onMagicLinkCopy = (shareId, email, inviteId) => {
        const md5Hash = CryptoJS.MD5(email+'|'+inviteId).toString();
        console.log(email+'|'+inviteId);
        let url = window.location.protocol + '//' + window.location.hostname + '/p/' + shareId + '/ml/' + md5Hash;

        navigator.clipboard.writeText(url).then(() => {
            notifications.notify('Magic link copied to clipboard', 'success');
        }).catch(err => {
            console.error('Failed to copy magic link: ', err);
        });
    }


    return (
        <SimpleModal visible={visible} onClose={onCancel} headerTitle={"Share this project"}>

            <div className="mb-3 lh-1">
                <span className="fs-2">Link to the project:</span><br/>
                <a href={'https://' + window.location.hostname + '/p/' + projectId}
                   className="fs-2">{'https://' + window.location.hostname + '/p/' + projectId}</a>
                <button
                    className="btn btn-sm btn-icon btn-light-primary text-primary ms-3"
                    onClick={() => {
                        navigator.clipboard.writeText('https://' + window.location.hostname + '/p/' + projectId).then(() => {
                            //console.log('Text copied to clipboard');
                            notifications.notify('Link copied to clipboard', 'success');
                        }).catch(err => {
                            //console.error('Failed to copy text: ', err);
                        });
                    }}
                >
                    <i className="ti ti-copy fs-2"></i>
                </button>
            </div>

            {universalAccessKey ? (
                <div className="mb-3 lh-1">
                    <span className="fs-2">Universal read access to the project:</span><br/>
                    <a href={'https://' + window.location.hostname + '/p/' + projectId + '/ua/' + universalAccessKey}
                       className="fs-2">{'https://' + window.location.hostname + '/p/.../ua/' + universalAccessKey}</a>
                    <button
                        className="btn btn-sm btn-icon btn-light-primary text-primary ms-3"
                        onClick={() => {
                            navigator.clipboard.writeText('https://' + window.location.hostname + '/p/' + projectId + '/ua/' + universalAccessKey).then(() => {
                                //console.log('Text copied to clipboard');
                                notifications.notify('Link copied to clipboard', 'success');
                            }).catch(err => {
                                //console.error('Failed to copy text: ', err);
                            });
                        }}
                    >
                        <i className="ti ti-copy fs-2"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-icon btn-light-danger text-danger ms-3"
                        onClick={refreshUniversalAccessKey}
                    >
                        <i className="ti ti-refresh fs-2"></i>
                    </button>
                </div>
            ) : ''}

            {shares && shares.length ? (
                <div className="mb-4">
                    <h4 className="text-uppercase fs-2 fw-semibold mb-2 pb-1">Users With Access</h4>
                    {shares.map((share, index) => {
                        if (!share.invited) {return '';}
                        
                        return (
                        <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <div>{share.invited.name}</div>
                                <div className="fs-1 text-muted">{share.invited.email}</div>
                                <div className="text-muted text-capitalize fs-2">{share.level}</div>
                            </div>

                            <div key={index} className="d-flex justify-content-between align-items-center gap-2">
                                <ButtonWithLoading
                                    className="btn-circle btn-light-primary text-primary btn-sm justify-content-center"
                                    iconClassName="ti ti-copy fs-2"
                                    noOriginalIconClassName={true}
                                    loading={shareLoading === share.id}
                                    spinnerClassName="spinner-border spinner-border-sm"
                                    noOriginalSpinnerClassName={true}
                                    onClick={() => onMagicLinkCopy(projectId, share.invited.email, share.invited.id)}
                                />

                                <ButtonWithLoading
                                    className="btn-circle btn-light btn-sm justify-content-center"
                                    iconClassName="ti ti-x fs-3"
                                    noOriginalIconClassName={true}
                                    loading={shareLoading === share.id}
                                    spinnerClassName="spinner-border spinner-border-sm"
                                    noOriginalSpinnerClassName={true}
                                    onClick={() => {
                                        onRemoveAccessClick(share.id)
                                    }}
                                />
                            </div>
                        </div>
                    )})}
                </div>
            ) : ('')}


            <h4 className="mb-2 pb-1 text-uppercase fs-2 fw-semibold">Add new Access</h4>

            <div className="mb-3">
                <p className="mb-1">Select user to share with:</p>

                <ProjectShareAutocomplete user={''} onChange={onSelectUser}/>
            </div>

            <div className="d-flex align-items-end justify-content-between gap-3">
                <select className="form-select w-50" onChange={onLevelChange}>
                    <option value="read">Read</option>
                    <option value="edit">Edit</option>
                </select>

                <button className="btn btn-primary" onClick={onShareClick}>Share</button>
            </div>
        </SimpleModal>
    );
}
