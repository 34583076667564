import React, {useEffect} from 'react';
import {useNotifications} from "../../../../providers/NotificationsProvider";
import SimpleModal from "../../../ui/SimpleModal";
import ButtonWithLoading from "../../../layout/ui/ButtonWithLoading";
import ProjectShareAutocomplete from "../../../projects/ProjectShareAutocomplete";
import {useApi} from "../../../../providers/ApiProvider";
import ShapesModel from "../../../../models/ShapesModel";
import ProjectsModel from "../../../../models/ProjectsModel";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../../providers/AuthProvider";

export default function EmailReportModal({locations, visible = false, onCancel}) {
    const notifications = useNotifications();
    const apiContext = useApi();
    const {projectId} = useParams();
    const projectsModel = new ProjectsModel(apiContext.api);

    const [modalLoading, setModalLoading] = React.useState(false);
    const [requestLoading, setRequestLoading] = React.useState(false);


    const onSubmit = () => {
        setRequestLoading(true);

        let data = {
            totalRecords: locations && locations.stats && locations.stats.positive ? locations.stats.positive : 0,
            catIds: locations && locations.positive ? locations.positive : []
        };

        projectsModel.requestReport(projectId, data).then((response) => {
            if (response !== false) {
                if (response.request_id) {
                    notifications.notify('Report requested successfully!', 'success');
                } else if (response.message) {
                    notifications.notify(response.message, 'warning');
                }
            }
        }).finally(() => {
            setRequestLoading(false);
            onCancel();
        });
    }


    return (
        <SimpleModal visible={visible} onClose={onCancel} loading={modalLoading} headerTitle="Request a report">
            <div className="fs-3">
                <h5>You are requesting:</h5>
                Total Properties: <strong>{locations && locations.stats && locations.stats.positive ? locations.stats.positive : 0}</strong>

                <ButtonWithLoading
                    className="btn-primary justify-content-center mt-3"
                    iconClassName="ti ti-mail fs-4 me-2"
                    noOriginalIconClassName={true}
                    loading={requestLoading}
                    label={'Request Report'}
                    onClick={onSubmit}
                />
            </div>
        </SimpleModal>
    );
}
