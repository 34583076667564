import React, {useCallback, useEffect, useRef, useState} from 'react';

import Shape from "./Shape";
import ShapeStyling from "./ShapeStyling";

import MapWithMeasurement from './../general/ui/MapWithMeasurement';
import Locations from "./../locations/Locations";
import ShapeInfoPanel from "./../general/ui/ShapeInfoPanel";


export default function ShapePanelControl({ locations, shapes, onShapesChanged, onAddShapeClick, onShapeDelete, onShapeActiveChange, checkAccess, isEditable = false }) {

    const [rulerActive, setRulerActive] = useState(false);
    const [btnActive, setBtnActive] = useState('');
    const emptyLocations = useRef({positive: [], negative: [], counts: {total: 0, positive: 0, negative: 0}});


    const onClick = (e) => {
        e.preventDefault();
        checkAccess();
    }


    const onRefreshClick = (e) => {
        e.preventDefault();
        checkAccess();

        onShapesChanged();
    }


    const onRulerClick = (e) => {
        e.preventDefault();
        checkAccess();

        setRulerActive(!rulerActive);
    }


    const onActivityFinished = () => {
        setBtnActive('');
    }


    const onAddClick = (e, type) => {
        e.preventDefault();
        checkAccess();
        setBtnActive('new-shape-'+type);

        onAddShapeClick(type);
    }


    const onShapeChange = (shapeId, union, visible = null, shouldUpdateProject = true) => {
        let newShapes = {...shapes};
        newShapes[shapeId].geoJSON.properties.union = union;

        if (visible !== null) {
            newShapes[shapeId].geoJSON.properties.visible = visible;
        }

        onShapesChanged(newShapes, true, shouldUpdateProject);
    }


    return shapes ? Object.keys(shapes).map((shapeId, index) => (
        <Shape
            key={shapeId}
            id={shapeId}
            data={shapes[shapeId]}
            locations={locations.byShape && locations.byShape[shapeId] ? locations.byShape[shapeId] : emptyLocations.current}
            active={shapes[shapeId].active}
            visible={!(shapes[shapeId].geoJSON && shapes[shapeId].geoJSON.properties && shapes[shapeId].geoJSON.properties.visible === false)}
            isEditable={isEditable}

            onActiveChange={onShapeActiveChange}
            onSelfDelete={onShapeDelete}
            onCreateFinished={onActivityFinished}
            onShapeChange={onShapeChange}
        />
    )) : null;
}